/* eslint-disable import/no-unresolved */
import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const GeneralInstructions = lazy(() => import('./generalInstructions'));
const TestMicrophone = lazy(() => import('./microphoneTestScreen'));
const SectionTests = lazy(() => import('./sectionTests'));
const NotFound = lazy(() => import('../pages/404'));

function Evaluation() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="w-full h-full flex items-center justify-center">
          <div className="spin">
            <Spin />
          </div>
        </div>
      }
    >
      <Routes>
        <Route index path="general-instructions/*" element={<GeneralInstructions />} />
        <Route path="test-microphone/*" element={<TestMicrophone />} />
        <Route path="section/*" element={<SectionTests />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default Evaluation;
